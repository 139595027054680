import { request } from '@umijs/max';

export type LoginType = 'phone' | 'account';

export type LoginParams = {
  username?: string;
  password?: string;
  autoLogin?: boolean;
  cfToken?: string;
};

export type SsoParams = {
  username?: string;
  password?: string;
  redirect_url?: string;
  app_id?: string;
};

export type TokenParams = {
  user_token?: string;
  redirect_url?: string;
  app_id?: string;
};

interface LoginData extends SERVICE.RequestData {
  userId?: string;
}

interface SsoData extends SERVICE.RequestData {
  userId?: string;
  token?: string;
  appId?: string;
}

interface TokenData extends SERVICE.RequestData {
  userId?: string;
  token?: string;
  appId?: string;
}

export type UserArray = {
  key: string;
  label: string;
};

export type UserParams = {
  id: number;
  name: string;
  user_name?: string;
  avatar?: string;
  app_id?: string;
  app_secret?: string;
  signature?: string;
  logined_at?: string;
  access: number[];
  role_name?: string[];
  is_lock?: number;
};

/**
 * 登陆接口
 * @param body
 * @param options
 * @returns
 */
export async function loginHandle(
  body: LoginParams,
  options?: { [key: string]: any },
) {
  return request<LoginData>('/admin/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/**
 * 统一登陆接口
 * @param body
 * @param options
 * @returns
 */
export async function SsoHandle(
  body: SsoParams,
  options?: { [key: string]: any },
) {
  return request<SsoData>('/api2/sso/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/**
 * 统一登陆接口
 * @param body
 * @param options
 * @returns
 */
export async function TokenHandle(
  body: TokenParams,
  options?: { [key: string]: any },
) {
  return request<TokenData>('/api2/sso/token', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/**
 * 获取用户信息接口
 * @param options
 * @returns
 */
export async function getUserInfo(options?: { [key: string]: any }) {
  return request<SERVICE.RequestData>('/admin/login/getUserInfo', {
    method: 'GET',
    ...(options || {}),
  });
}

/**
 * 获取人机验证参数
 */
export async function getTurnstile() {
  return request<SERVICE.RequestData>('/admin/login/turnstile');
}

export default {
  loginHandle,
  getUserInfo,
  SsoHandle,
  TokenHandle,
  getTurnstile,
};
