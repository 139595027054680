import { DefaultFooter } from '@ant-design/pro-components';
import React from 'react';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear(); // 获取当前年份

  return (
    <DefaultFooter
      links={[
        {
          key: 'icp',
          title: '粤ICP备2021161038号-1',
          href: 'https://beian.miit.gov.cn/', // 跳转到管局的链接
          blankTarget: true, // 在新标签页中打开
        },
      ]}
      copyright={`${currentYear} 广东择才猫科技有限公司`} // 动态插入当前年份
    />
  );
};

export default Footer;
