// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import HomeOutlined from '/opt/base_service_admin/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/HomeOutlined';
import MessageOutlined from '/opt/base_service_admin/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/MessageOutlined';
import LinkOutlined from '/opt/base_service_admin/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/LinkOutlined';
import FileSearchOutlined from '/opt/base_service_admin/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/FileSearchOutlined';
import SlackOutlined from '/opt/base_service_admin/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/SlackOutlined';
import SettingOutlined from '/opt/base_service_admin/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/SettingOutlined';
import CodepenOutlined from '/opt/base_service_admin/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/CodepenOutlined';
import ShareAltOutlined from '/opt/base_service_admin/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/ShareAltOutlined';
export default { HomeOutlined, MessageOutlined, LinkOutlined, FileSearchOutlined, SlackOutlined, SettingOutlined, CodepenOutlined, ShareAltOutlined };
