import Footer from '@/components/Footer';
import RightRender from '@/components/RightRender';
import type { UserParams } from '@/services/login';
import service from '@/services/login';
import EscapeAntd, { message } from '@/utils/EscapeAntd';
import type { AxiosResponse, RequestConfig } from '@umijs/max';
import { RunTimeLayoutConfig, RuntimeAntdConfig, history } from '@umijs/max';
import { App, Watermark } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import React from 'react';

// 运行时配置
export function rootContainer(container: React.ReactNode) {
  return (
    <App>
      <EscapeAntd />
      {container}
    </App>
  );
}

// 全局初始化数据配置，用于 Layout 用户信息和权限初始化
// 更多信息见文档：https://umijs.org/docs/api/runtime-config#getinitialstate

const excludePaths = ['/authLogin', '/login']; // 需要排除的路径

export async function getInitialState(): Promise<UserParams> {
  const jwt = localStorage.getItem('userToken');
  const currentPath = history.location.pathname;

  // 排除路径不执行跳转
  if (excludePaths.includes(currentPath)) {
    return { id: 0, name: '', access: [-1] };
  }

  if (jwt && typeof jwt === 'string') {
    try {
      // 尝试获取用户信息
      const { data, code, msg } = await service.getUserInfo({
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      if (code === 200) {
        return data; // 成功时返回用户数据
      } else if (code === 403) {
        history.push(
          `/notWhitelist?ip_address=${data.ip_address}&path=${data.path}`,
        );
        return { id: 0, name: '', access: [-1] };
      }
      message.error(msg); // 显示服务端错误信息
    } catch (error) {
      // 处理网络错误或请求异常
      message.error('获取用户信息失败，请重新登录');
    }
    // 任何错误都跳转到登录页
    window.location.href = `/login?redirect=${history.location.pathname}`;
    // history.push(`/login?redirect=${encodeURIComponent(currentPath)}`);
    return { id: 0, name: '', access: [-1] };
  }

  // 无有效 token 直接跳转
  window.location.href = `/login?redirect=${history.location.pathname}`;
  // history.push(`/login?redirect=${encodeURIComponent(currentPath)}`);
  return { id: 0, name: '', access: [-1] };
}

/**
 * 返回运行时的antd配置
 * @param memo
 * @returns RuntimeAntdConfig
 */
export const antd: RuntimeAntdConfig = (memo) => {
  memo.locale = zhCN;
  memo.theme = {
    token: {
      borderRadius: 8,
      colorPrimary: '#02407d',
      colorLink: '#2b5677',
    },
  };
  dayjs.locale('zh-cn');
  return memo;
};

/**
 * 返回运行时的layout配置
 * @param initialStateAction
 * @returns RunTimeLayoutConfig
 */
export const layout: RunTimeLayoutConfig = (initialStateAction) => {
  const date = dayjs().format('YYMMDD');
  return {
    title: '基础服务可视化平台',
    logo: 'https://zecaimao-static.static.zecaimao.cn/base_service/dist/favicon.ico',
    style: {
      minHeight: '98vh',
    },
    bgLayoutImgList: [
      {
        src: 'https://img.alicdn.com/imgextra/i2/O1CN01O4etvp1DvpFLKfuWq_!!6000000000279-2-tps-609-606.png',
        left: 85,
        bottom: 100,
        height: '303px',
      },
      {
        src: 'https://img.alicdn.com/imgextra/i2/O1CN01O4etvp1DvpFLKfuWq_!!6000000000279-2-tps-609-606.png',
        bottom: -68,
        right: -45,
        height: '303px',
      },
      {
        src: 'https://img.alicdn.com/imgextra/i3/O1CN018NxReL1shX85Yz6Cx_!!6000000005798-2-tps-884-496.png',
        bottom: 0,
        left: 0,
        width: '331px',
      },
    ],
    fixSiderbar: true,
    layout: 'mix',
    splitMenus: false,
    siderWidth: 216,
    rightRender: (initialState: any) => (
      <RightRender action={initialStateAction} initialState={initialState} />
    ),
    // 默认布局调整
    footerRender: Footer,
    childrenRender: (children) => (
      <Watermark
        content={`${initialStateAction?.initialState?.name || ''} ${date}`}
        font={{
          color: 'rgba(0, 0, 0, 0.07)',
          fontSize: 16,
        }}
        zIndex={1000}
        gap={[50, 20]} // 可调整水印间距
      >
        {children}
      </Watermark>
    ),
  };
};

// 枚举错误代码
enum CodeType {
  SUCCESS = 200,
  UNAUTHORIZED = 401,
  ERROR = 400,
  UNWHITELIST = 403,
  UNFOUND = 404,
  REDIRECT = 301,
  SYSTEMERROR = 500,
}

// Request运行时配置
export const request: RequestConfig = {
  timeout: 120 * 1000,
  headers: {
    'Content-Type': 'application/json',
  },
  responseInterceptors: [
    (response: AxiosResponse<any>) => {
      // 拦截响应数据，进行个性化处理
      const data = response.data as SERVICE.RequestData;
      if (!data.success) {
        switch (data.code) {
          case CodeType.ERROR:
          case CodeType.UNFOUND:
          case CodeType.SYSTEMERROR:
            message.error(data.msg);
            break;
          case CodeType.UNWHITELIST:
            message.error(data.msg);
            history.push(
              `/notWhitelist?ip_address=${data.data.ip_address}&path=${data.data.path}`,
            );
            break;
          case CodeType.UNAUTHORIZED:
          case CodeType.REDIRECT:
            message.error(data.msg);
            setTimeout(() => {
              if (history.location.pathname !== '/login')
                window.location.href = `/login?redirect=${history.location.pathname}`;
            }, 1000);
            break;
        }
      }
      return response;
    },
  ],
};
